import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import vBlur from "vblur";
import { store } from "./store";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons/faCheckSquare";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons/faExpandAlt";
import { faCompressAlt } from "@fortawesome/free-solid-svg-icons/faCompressAlt";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons/faCaretRight";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faBolt } from "@fortawesome/free-solid-svg-icons/faBolt";
import { faTasks } from "@fortawesome/free-solid-svg-icons/faTasks";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons/faAsterisk";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faQuestionCircle as farQuestionEmptyCircle } from "@fortawesome/free-regular-svg-icons/faQuestionCircle";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faPrint } from "@fortawesome/free-solid-svg-icons/faPrint";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { faFilter } from "@fortawesome/free-solid-svg-icons/faFilter";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons/faFolderOpen";
import { faTable } from "@fortawesome/free-solid-svg-icons/faTable";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faCopy } from "@fortawesome/free-solid-svg-icons/faCopy";
import { faPen } from "@fortawesome/free-solid-svg-icons/faPen";
import { faCircle as fasCircle } from "@fortawesome/free-solid-svg-icons/faCircle";
import { faMars } from "@fortawesome/free-solid-svg-icons/faMars";
import { faVenus } from "@fortawesome/free-solid-svg-icons/faVenus";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { faTextWidth } from "@fortawesome/free-solid-svg-icons/faTextWidth";
import { faChartBar } from "@fortawesome/free-solid-svg-icons/faChartBar";
import { faHourglass } from "@fortawesome/free-solid-svg-icons/faHourglass";
import { faSave } from "@fortawesome/free-solid-svg-icons/faSave";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons/faPlayCircle";
import { faPauseCircle } from "@fortawesome/free-solid-svg-icons/faPauseCircle";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons/faAngleDoubleRight";
import { faQrcode } from "@fortawesome/free-solid-svg-icons/faQrcode";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkSquareAlt";
import { faSquare } from "@fortawesome/free-solid-svg-icons/faSquare";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons/faHouse";
import { faDiagramProject } from "@fortawesome/free-solid-svg-icons/faDiagramProject";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons/faCommentDots";
import { faChartArea } from "@fortawesome/free-solid-svg-icons/faChartArea";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faLockOpen } from "@fortawesome/free-solid-svg-icons/faLockOpen";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons/faPaperclip";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons/faCreditCard";
import { faGear } from "@fortawesome/free-solid-svg-icons/faGear";
import { faSort } from "@fortawesome/free-solid-svg-icons/faSort";
import { faRefresh } from "@fortawesome/free-solid-svg-icons/faRefresh";
import { faFire } from "@fortawesome/free-solid-svg-icons/faFire";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons/faArrowUpFromBracket";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faHospital } from "@fortawesome/free-solid-svg-icons/faHospital";
import { faX } from "@fortawesome/free-solid-svg-icons/faX";
import { faMobileScreen } from "@fortawesome/free-solid-svg-icons/faMobileScreen";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons/faFileSignature";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faClock as farClock } from "@fortawesome/free-regular-svg-icons/faClock";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";

import { faFolderTree } from "@fortawesome/free-solid-svg-icons/faFolderTree";
import { faHeart } from "@fortawesome/free-solid-svg-icons/faHeart";
import { faCrosshairs } from "@fortawesome/free-solid-svg-icons/faCrosshairs";
import { faCheckToSlot } from "@fortawesome/free-solid-svg-icons/faCheckToSlot";
import { faHardDrive } from "@fortawesome/free-solid-svg-icons/faHardDrive";
import { faBan } from "@fortawesome/free-solid-svg-icons/faBan";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons/faCaretUp";

import { faBarChart } from "@fortawesome/free-solid-svg-icons/faBarChart";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons/faSquarePlus";
import { faSquareMinus } from "@fortawesome/free-solid-svg-icons/faSquareMinus";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons/faUserGroup";

library.add(
  faInfoCircle,
  faPlusCircle,
  faTimes,
  faTimesCircle,
  faCheckSquare,
  faCheckCircle,
  faEye,
  faEyeSlash,
  faSearch,
  faCog,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faUsers,
  faUser,
  faBolt,
  faTasks,
  faAsterisk,
  faAngleDown,
  faAngleRight,
  faAngleLeft,
  faAngleUp,
  faExclamationCircle,
  faExclamationTriangle,
  faQuestionCircle,
  farQuestionEmptyCircle,
  faCheck,
  faPrint,
  faEllipsisV,
  faFilter,
  faFolderOpen,
  faTable,
  faTextWidth,
  faTrash,
  faCopy,
  faPen,
  fasCircle,
  faMars,
  faVenus,
  faDownload,
  faChartBar,
  faHourglass,
  faSave,
  faEdit,
  faPlay,
  faPlayCircle,
  faPauseCircle,
  faEnvelope,
  faCalendar,
  faExpandAlt,
  faCompressAlt,
  faAngleDoubleRight,
  faQrcode,
  faPaperPlane,
  faSquare,
  faExternalLinkSquareAlt,
  faHouse,
  faGear,
  faDiagramProject,
  faCommentDots,
  faChartArea,
  faPaperclip,
  faCreditCard,
  faSort,
  faArrowUpFromBracket,
  faRefresh,
  faFire,
  faX,
  faMobileScreen,
  faFileSignature,
  faFolderTree,
  faHeart,
  faCrosshairs,
  faCheckToSlot,
  faHardDrive,
  faArrowUpFromBracket,
  faArrowLeft,
  faArrowRight,
  faBars,
  faLock,
  faLockOpen,
  faClock,
  farClock,
  faFire,
  faRefresh,
  faHospital,
  faCircleXmark,
  faImage,
  faUpload,
  faExpand,
  faTag,
  faBan,
  faFile,
  faArrowUpRightFromSquare,
  faBarChart,
  faSquareMinus,
  faSquarePlus,
  faMinus,
  faSpinner,
  faUserGroup
);
const app = createApp(App);
const options = {
  // You can set your default options here
  position: POSITION.BOTTOM_CENTER,
  toastClassName: "toast-comtainer",
  transition: "Vue-Toastification__fade",
};
app.use(Toast, options);

app.use(router);
app.use(store);
app.use(vBlur);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)
app.use(pinia);
app.directive('focus', {
  // When the bound element is mounted into the DOM...
  mounted(el) {
    // Focus the element
    el.focus()
  }
})
app.component("font-awesome-icon", FontAwesomeIcon);

app.mount("#app");

// User guiding
const ug_id = import.meta.env.VITE_USERGUIDING;
if (ug_id) {
  (function (g, u, i, d, e, s) {
    g[e] = g[e] || [];
    var f = u.getElementsByTagName(i)[0];
    var k = u.createElement(i);
    k.async = true;
    k.src = "https://static.userguiding.com/media/user-guiding-" + s + "-embedded.js";
    f.parentNode.insertBefore(k, f);
    if (g[d]) return;
    var ug = (g[d] = {
      q: [],
    });
    ug.c = function (n) {
      return function () {
        ug.q.push([n, arguments]);
      };
    };
    var m = ["previewGuide", "finishPreview", "track", "identify", "triggerNps", "hideChecklist", "launchChecklist"];
    for (var j = 0; j < m.length; j += 1) {
      ug[m[j]] = ug.c(m[j]);
    }
  })(window, document, "script", "userGuiding", "userGuidingLayer", ug_id);
}
