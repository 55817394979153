<template>
  <nav class="primary" role="navigation" :class="{ top: true, fixed: isFixed, static: !isFixed }">
    <router-link id="logo" to="/organization" title="Home" style="height: 24px"
      ><img alt="Logo" src="@/assets/img/logo-snake-sm-transparent.png" /></router-link
    ><TopSearch v-if="route.meta.magicSearch"></TopSearch>
    <div id="sessionmenu">
      <template v-if="isLoggedIn">
        <div class="trial-status" v-if="showTrial" style="width: 11.2em; text-align: center">
          <Process class="process" margin="0.6rem 0" :color="trialColor" :ratio="trialRatio" :height="8"></Process>
          <div style="font-size: 0.9em">{{ trialLabel }}</div>
        </div>

        <div class="account-status" style="margin-left: 3rem">
          <router-link v-if="isAdmin" class="is_admin" to="/bo">Administrator</router-link>
          <template v-if="!isAdmin && !currentRouteIsPaywall">
            {{ roleDisplay
            }}<a
              v-if="showUpgradeLink"
              style="display: block; font-weight: 600; color: white"
              @click.prevent="upgrade"
              >{{ upgradeLinkText }}</a
            >
          </template>
        </div>

        <div class="dropdown">
          <span class="initials">{{ initials }}</span>
          <a
            href="#"
            class="dropdown-toggle text-green-light"
            id="dropdownAccountMenu"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
            @click.prevent="showMenu = !showMenu"
          >
            <span style="margin-right: 0.5em">My account</span>
            <font-awesome-icon icon="caret-down" />
          </a>
          <ul
            class="dropdown-menu"
            aria-labelledby="dropdownAccountMenu"
            style="margin-top: 14px"
            v-show="showMenu"
            v-cloak
          >
            <li>
              <router-link :to="{ name: 'SeriesList', query: { no_redirect: 1 } }" @click="showMenu = false"
                >My series</router-link
              >
            </li>
            <li role="separator" class="divider"></li>
            <li>
              <router-link :to="{ name: 'user_profile' }" activeClass="" @click="showMenu = false"
                >Account settings</router-link
              >
            </li>
            <li><a href="#" @click.prevent="logout">Logout</a></li>
          </ul>
        </div>
      </template>
      <div v-if="!is_unauth_session_page && !isLoggedIn">
        <a class="btn-header btn-default-header" id="loginButton"><font-awesome-icon icon="user" />&nbsp; Sign in</a>
      </div>
    </div>
    <CheckoutSuccess v-if="show_success"></CheckoutSuccess>
  </nav>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";
import { ApiMessage } from "@/ems/WSClient";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { php_app_url } from "@/router/utils";
import { getFirstParam } from "@/ems/utils";
import mixpanel from "@/ems/mixpanel";

import CheckoutSuccess from "@/components/CheckoutSuccess.vue";
import Process from "@/components/Process.vue";
import TopSearch from "@/components/TopSearch.vue";

const legacyPremiumTeam = ["corporate-team", "academic-team", "premium-team", "serie-vip"];

const legacyPremium = [
  "academic-researcher",
  "corporate-individual",
  "student",
  "academic-power-researcher",
  ...legacyPremiumTeam,
];

const store = useStore(),
  route = useRoute(),
  router = useRouter();

const props = defineProps<{
  hide_login_button?: boolean;
}>();

const showMenu = ref(false),
  show_success = ref(false),
  demo_series_id = ref(0),
  loading = ref(false),
  messages = ref<ApiMessage[]>([]);

let mixPanelInstance: any = null;

const logout = async () => {
    showMenu.value = false;
    store
      .dispatch("logout")
      .then(() => router.push({ name: "login", query: { redir: "reset" } }))
      .catch((err) => {
        messages.value = err.messages;
      });
    await mixpanel.getMixpanel(getCurrentInstance(), store).then((mp) => {
      mp.reset();
    });
  },
  upgrade = async () => {
    mixpanel &&
      (await mixpanel.getMixpanel(this).then((mp) => {
        mp && mp.track("upgrade-clicked");
      }));
    router.push(linkToPaywall.value);
  };

const isFixed = computed(() => {
    return route.meta.fixedNav;
  }),
  showUpgradeLink = computed(() => {
    if (logged_user.value?.is_admin) return false;

    const seriePlan = serie.value?.current_plan?.name;
    if (seriePlan && legacyPremiumTeam.includes(seriePlan)) return false;

    const userPlan = logged_user.value?.current_plan?.name;
    if (userPlan && ["academic-team-v3", "corporate-pro-v3"].includes(userPlan)) return false;
    return true;
  }),
  linkToPaywall = computed(() => {
    let name = "offers";
    const cp = currentPlan.value;
    if (["academic-researcher-v3", "corporate-individual-v3"].includes(cp?.name)) {
      name = "EcrfPaywall";
    }
    return { name: name, query: { serie_id: serieId.value } };
  }),
  currentRouteIsPaywall = computed(() => {
    return (
      current_route.value == "offers" ||
      current_route.value == "EcrfPaywall" ||
      current_route.value == "ProSubscription" ||
      current_route.value == "BillingInformations"
    );
  }),
  currentRouteIsOrganization = computed(() => {
    return current_route.value && current_route.value.includes("Organization");
  }),
  current_route = computed((): string => {
    if (!route.name) return "none";
    return route.name.toString();
  }),
  is_unauth_session_page = computed(() => {
    return (
      current_route.value === "login" || current_route.value === "signup" || current_route.value === "ask_password"
    );
  }),
  initials = computed(() => {
    let lu = logged_user.value,
      letters = lu && (lu.FirstName && lu.FirstName.charAt(0)) + (lu.LastName && lu.LastName.charAt(0));
    return letters && letters.toUpperCase();
  }),
  roleDisplay = computed(() => {
    return currentPlan.value?.display_name;
  }),
  currentPlan = computed(() => {
    return (serieId.value && serie.value?.current_plan) || logged_user.value?.current_plan;
  }),
  upgradeLinkText = computed(() => {
    return "Upgrade now";
  }),
  showTrial = computed(() => {
    return (
      (payment_status.value === "unpaying" || payment_status.value === "trial") &&
      !legacyPremium.includes(current_route.value) &&
      !currentRouteIsPaywall.value
    );
  }),
  trialColor = computed(() => {
    if (trial_days_passed.value === 2) return "#FFB800"; // Orange
    if (trial_days_passed.value < 2) return "#00E132"; // Green
    if (trial_days_left.value === 0 || trialRatio.value === 100) return "#FF2E00"; // Red
    return "#FFB800"; // Orange
  }),
  trialRatio = computed((): number => {
    if (payment_status.value === "unpaying") return 100;
    if (trial_days_left.value === 2) return 5;
    if (trial_days_left.value === 1) return 50;
    if (trial_days_left.value === 0) return 100;
    if (trial_days_left.value < 0) return 100;
    return 0;
  }),
  trial_days_passed = computed(() => {
    if (payment_status.value === "unpaying") return logged_user.value?.trial?.total_days;
    return logged_user.value?.trial?.total_days - trial_days_left.value;
  }),
  trial_days_left = computed(() => {
    return logged_user.value?.trial?.days_left || 0;
  }),
  trialLabel = computed(() => {
    if (!logged_user.value?.trial || logged_user.value?.trial?.is_expired || trial_days_left.value < 0)
      return "Your free trial has ended";
    if (trial_days_left.value == 0) return "Last trial day. Upgrade!";
    if (trial_days_left.value == 1) return "1 trial day left";
    return trial_days_left.value + " trial days left";
  }),
  payment_status = computed(() => {
    return logged_user.value?.payment_status;
  }),
  serieId = computed((): string | null => {
    if (!route.params?.series_id) return null;
    return getFirstParam(route.params?.series_id);
  }),
  isLoggedIn = computed(() => {
    return store.getters.isLoggedIn;
  }),
  logged_user = computed(() => {
    return store.getters.logged_user;
  }),
  isAdmin = computed(() => {
    return store.getters.isAdmin;
  }),
  serie = computed(() => {
    return store.getters.serie;
  }),
  organization = computed(() => {
    return store.getters.organization;
  }),
  account_url = computed((): string => {
    return php_app_url("user.php?o=32");
  });

onMounted(async () => {
  // Could have been logged out or upgraded in non vue context
  const rt = route.name;
  if (rt != "login" && rt != "signup") store.dispatch("session_check", { force_query: true });

  const envParams = await store.dispatch("get_env_params", ["DEMO_SERIES_ID", "USERGUIDING_ID"]);
  demo_series_id.value = envParams.demo_series_id;

  const urlParams = new URLSearchParams(window.location.search),
    checkout = urlParams.get("checkout_success");

  if (checkout?.toString() == "1") {
    show_success.value = true;
    mixpanel.getMixpanel(this).then((mp) => {
      mp && mp.track("checkout-return", { Result: "Succeeded" });
    });
  } else if (checkout === "0") {
    mixpanel.getMixpanel(this).then((mp) => {
      mp && mp.track("checkout-return", { Result: "Failed" });
    });
  }
});

watch(
  () => route.path,
  (path) => {
    let main = document.querySelector("main") as HTMLElement;

    if (!main) return;
    if (isFixed.value) {
      main.setAttribute("style", "margin: 0 !important");
    } else {
      main.setAttribute("style", "margin 1rem");
    }
  },
  { immediate: true }
);

watch(
  () => route.query.checkout_success,
  (is_success) => {
    if (getFirstParam(is_success) === "1") {
      show_success.value = true;
    } else {
      show_success.value = false;
    }
  },
  { immediate: true }
);
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
nav {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 0 1.9rem;
  z-index: 1030;
  background: linear-gradient(135deg, rgb(0, 117, 93), rgb(0, 168, 134)) 0% 0% / contain;
}

.fixed {
  position: fixed;
}

.static {
  position: static;
}

.top {
  top: 0;
}

nav.primary #logo {
  margin: 13px 0;
}

#sessionmenu {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}

#sessionmenu > * {
  color: var(--green-light-color);
  display: inline-block;
}

#sessionmenu .initials {
  border-radius: 50%;
  background-color: var(--green-light-color);
  padding: 10px 8px 10px;
  color: var(--green-main-color);
  margin: 0 3rem;
}

#sessionmenu i.fas {
  font-size: 0.8em;
  margin-left: 0.4em;
}

.dropdown {
  position: relative;
}

a.is_admin {
  font-size: 14px;
  padding-top: 7px;
  font-weight: 600;
  color: #fff8c5;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 0px 0 0;
  font-size: 14px;
  list-style: none;
  background-color: white;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.175);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.dropdown-menu > li {
  padding: 0;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333;
  white-space: nowrap;
}

.dropdown-menu > li > a.router-link-active {
  font-weight: bold;
  color: var(--green-main-color);
  text-decoration: none;
}

.btn-header {
  border-width: 1px;
  border-radius: 30px;
  padding: 10px 20px 10px 20px;
  font-size: 16 px;
}

a.btn-header {
  line-height: 40px;
  cursor: pointer;
  text-decoration: none;
}

a.btn-default-header {
  border-color: rgba(0, 229, 159, 1);
  background-color: white;
  color: rgba(0, 229, 159, 1);
}

.btn-default-header:hover {
  background-color: rgba(0, 229, 159, 1);
  color: white;
}

.process {
  margin: 0.6rem 0px 0.8rem;
  background-color: #f2f2f2;
  border: 0;
}
</style>
